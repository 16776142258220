/*
* @Author: qinuoyun
* @Date:   2021-04-29 15:42:11
* @Last Modified by:   qinuoyun
* @Last Modified time: 2021-04-29 15:42:36
*/

import tables from "./tables.vue";

export default tables;
